/**
 * 8. Site Footer
 * ----------------------------------------------------------------------------
 */
 .site-footer {
  padding-bottom: 1.66667em;
  padding-top: 1.66667em;
}

.site-footer-inside {
  position: relative;
}

.site-footer .social-links {
  text-align: center;
}

.site-footer .social-links a {
  margin: 0 3px 0.3125em;
}

.site-info {
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.back-to-top {
  margin: 1.66667em 0 0;
  text-align: center;
}