/**
 * 5.3 Paging Navigation
 * ----------------------------------------------------------------------------
 */
 .pagination {
  border-top: 1px solid $gray-lighter;
  margin-bottom: 3.33333em;
  margin-top: 1.66667em;
  padding-top: 3.33333em;
  text-align: center;
}

.pagination .nav-links {
  padding: 0 60px;
  position: relative;
}

.page-number {
  color: $gray;
  display: inline-block;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.newer-posts,
.older-posts {
  height: 30px;
  position: absolute;
  top: 0;
  width: 30px;
}

.newer-posts {
  left: 0;
}

.older-posts {
  right: 0;
}
