/**
 * 3. Structure
 * ----------------------------------------------------------------------------
 */
 .site {
  background: #fff;
  overflow: hidden;
  pointer-events: auto;
  position: relative;
  -webkit-transition: -webkit-transform .25s ease-in-out;
  -webkit-transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  z-index: 1;
}

.outer {
  padding-left: 3vw;
  padding-right: 3vw;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}

.inner-wide {
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
}