/**
 * 6. Sidebar
 * ----------------------------------------------------------------------------
 */
 .sidebar {
  background: $gray-darkest;
  bottom: 0;
  color: #fff;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: visibility 0s .25s;
  transition: visibility 0s .25s;
  visibility: hidden;
  width: 600px;
}

.sidebar-scrollable {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  position: relative;
}

.sidebar-inside {
  padding: 75px 60px 2.5em;
  position: relative;
}

#sidebar-hide {
  position: absolute;
  right: 3vw;
  top: 1.11111em;
}

.site-overlay {
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 0;
  cursor: ew-resize;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: right .25s ease-in-out, opacity .25s ease-in-out, visibility 0s .25s ease-in-out;
  transition: right .25s ease-in-out, opacity .25s ease-in-out, visibility 0s .25s ease-in-out;
  visibility: hidden;
}

.sidebar--opened .site {
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  -webkit-transform: translate(-600px, 0);
  transform: translate(-600px, 0);
  -webkit-transform: translate3d(-600px, 0, 0);
  transform: translate3d(-600px, 0, 0);
  width: 100%;
  z-index: 9998;
}

.sidebar--opened .sidebar {
  -webkit-transition: none;
  transition: none;
  visibility: visible;
}

.sidebar--opened .site-overlay {
  opacity: 1;
  right: 600px;
  -webkit-transition: opacity .25s ease-in-out, right .25s ease-in-out;
  transition: opacity .25s ease-in-out, right .25s ease-in-out;
  visibility: visible;
  z-index: 9999;
}

.sidebar .site-navigation {
  display: none;
}