/**
 * 4. Site Header
 * ----------------------------------------------------------------------------
 */
 .site-header {
  background: $gray-lightest;
  border-bottom: 1px solid $gray-lighter;
  margin: 0 0 3.33333em;
  padding-bottom: 1.11111em;
  padding-top: 1.11111em;
}

.site-header a {
  color: $gray-darkest;
}

.site-header a:hover {
  color: $color-accent;
}

.site-header-inside {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.site-branding {
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 30px;
}

.site-logo {
  margin: 0;
}

.site-logo img {
  max-height: 48px;
}

.site-title {
  color: $gray-darkest;
  font-size: 1.33333rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  text-rendering: optimizeLegibility;
}

.site-header .site-navigation {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}

.site-header .menu {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-header .menu-item {
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  margin: 0 25px 0 0;
}

.site-header .sidebar-toggle {
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: auto;
  position: relative;
}