/**
 * 5. Content
 * 5.1 Posts and Pages
 * ----------------------------------------------------------------------------
 */
 .post {
  margin-bottom: 1.66667em;
}

.post-header {
  margin-bottom: 1.66667em;
}

.post-title {
  color: $gray-darkest;
  font-size: 2.22222rem;
  margin: 0 0 0.75em;
}

.post-title a {
  color: inherit;
}

.post-meta {
  color: $gray;
  font-size: 0.77778rem;
  letter-spacing: 2px;
  line-height: 1.5;
  margin-bottom: 1.42857em;
  text-transform: uppercase;
}

.featured-badge {
  color: $color-accent;
}

.post-thumbnail {
  display: block;
  margin-bottom: 1.66667em;
}

.post-thumbnail img {
  width: 100%;
}

.post-footer {
  margin-bottom: 1.66667em;
}

.post-tags,
.post-share {
  font-size: 0.77778rem;
  line-height: 1.5;
}

.post-tags a {
  display: inline-block;
  margin: 0 10px 0 0;
}

.post-tags a:before {
  content: "\0023";
}

.post-share-title {
  color: $gray-darkest;
  display: inline-block;
  font-weight: bold;
}

.post-share a {
  display: inline-block;
  margin: 0 0 0 10px;
}

.author-box {
  border: 1px solid $gray-lighter;
  border-radius: 8px;
  margin: 1.66667em 0 3.33333em;
  padding: 1.66667em 30px;
  text-align: center;
}

.author-avatar {
  margin: 0 0 1.66667em;
}

.author-avatar .avatar {
  border: 2px solid $color-accent;
  border-radius: 100%;
  box-shadow: 0 0 1px 7px rgba($color-accent,.1);
  display: inline-block;
  height: 70px;
  width: 70px;
}

.author-title {
  font-size: 1rem;
  margin: 0 0 0.55556em;
}

.author-description {
  font-size: 14px;
  margin: 0.71428em 0;
}

.caption-text {
  color: $gray;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 1.66667em;
  padding-top: 1%;
  text-align: center;
}

/* Featured Posts */
.featured-posts {
  border-bottom: 1px solid $gray-lighter;
  margin-bottom: 5em;
  padding-bottom: 1.66667em;
}

.featured-posts .read-more {
  margin-top: 2.5em;
}

/* Post Feed */
.post-feed-title {
  color: $gray-dark;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 1.2;
  margin-bottom: 2.14285em;
  margin-top: 0;
  text-transform: uppercase;
}

.post-feed-title:after {
  background-color: $color-accent;
  display: block;
  content: "";
  height: 1px;
  margin-top: 15px;
  width: 60px;
}

.post-feed .post {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}

.post-feed .post-title {
  font-size: 1.55556rem;
  margin-bottom: 0.53571em;
}

.post-feed .post-meta {
  margin-bottom: 0.71428em;
}

/* Archives */
.page-header {
  border-left: 3px solid $color-accent;
  margin-bottom: 3.33333em;
  padding-left: 30px;
}

.page-title {
  font-size: 1.33333rem;
  margin: 0;
}

.page-description {
  color: $gray;
  font-size: 0.77778rem;
  line-height: 1.5;
  margin: 0.25em 0 0;
}

/* Read Next */
.read-next-title {
  color: $gray-dark;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2px;
  margin: 0 0 2.14285em;
  text-transform: uppercase;
}

.read-next-title:after {
  background-color: $color-accent;
  display: block;
  content: "";
  height: 1px;
  margin-top: 15px;
  width: 60px;
}

.read-next .post {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1.66667em;
}

.read-next .post-header {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-bottom: 0.83333em;
}

.read-next .post-meta {
  margin-bottom: 0.5em;
}

.read-next .post-title {
  font-size: 1.33333rem;
  margin: 0;
}

.read-next .post-tags {
  margin: 1.07142em 0 0;
}

.read-next .post-thumbnail {
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 0 0.83333em 30px;
  width: 150px;
}

/* Newsletter section */
.newsletter-box {
  background: $gray-lightest;
  border: 1px solid $gray-lighter;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 3.33333em;
  margin-top: 3.33333em;
  padding: 3.33333em 30px;
  text-align: center;
}

.newsletter-box-title {
  font-size: 2rem;
  margin: 0 0 0.41667em;
}

/* Hero */
.hero-wrap {
  background: $gray-lightest;
  border-bottom: 1px solid $gray-lighter;
  margin: 0 0 3.33333em;
  position: relative;
}

.hero-wrap .site-header {
  background: 0;
  border: 0;
  margin: 0;
  position: relative;
}

.hero-wrap-bg {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInOpacity;
  animation-name: fadeInOpacity;
  background-position: center;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: .15;
  position: absolute;
  right: 0;
  top: 0;
}

.hero {
  padding-bottom: 1.66667em;
  padding-top: 3.33333em;
  position: relative;
  text-align: center;
}

.hero-avatar {
  margin-bottom: 3.33333em;
}

.hero-avatar .avatar {
  border: 4px solid $color-accent;
  border-radius: 100%;
  box-shadow: 0 0 1px 11px rgba($color-accent,.15), 0 0 1px 22px rgba($color-accent,.1);
  display: inline-block;
  height: 130px;
  width: 130px;
}

.hero-title {
  font-size: 2.22222rem;
  margin: 0 0 0.75em;
}

.hero .social-links a {
  height: 36px;
  line-height: 36px;
  margin: 0 3px 0.5em;
  width: 36px;
}